import { FunctionComponent } from 'preact';
import EsignDetailsViewer from '../components/EsignViewer/EsignDetailsViewer';

interface Props {
	signaturePackageFprint: string;
}

const Esign: FunctionComponent<Props> = () => {
	return <EsignDetailsViewer />;
};
export default Esign;
