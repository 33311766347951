import { FunctionComponent } from 'preact';
// @ts-expect-error
import Button from '@mystacks/button';

interface Props {
	title?: string;
	remainingNeededSignatureCount: null | number;
	goToNextSignature: () => void;
	handleSubmitSignature: () => void;
	handleDeclineSignature: () => void;
	handleDownloadPdf: () => Promise<void>;
	declineReasonSubmitted: string | null;
}

const EsignTools: FunctionComponent<Props> = ({
	title,
	remainingNeededSignatureCount,
	goToNextSignature,
	handleSubmitSignature,
	handleDeclineSignature,
	handleDownloadPdf,
	declineReasonSubmitted,
}) => {
	const onClickNext = () => {
		goToNextSignature();
	};
	const onClickSubmit = () => {
		handleSubmitSignature();
	};

	const handleOtherActions = (event: any) => {
		if (event.target.value === 'download') {
			handleDownloadPdf();
			event.target.options[1].selected = false; // reset
		} else if (event.target.value === 'decline') {
			handleDeclineSignature();
			event.target.options[2].selected = false; // reset
		}
	};

	const renderCounter = () => {
		if (remainingNeededSignatureCount === null) return;

		if (remainingNeededSignatureCount === 0) {
			return <div className="text-sm">All signatures completed</div>;
		}

		return (
			<div className="flex items-center gap-2 text-sm mr-6">
				<div id="counter_label">Signature fields left</div>
				<div
					style={{
						backgroundColor: '#111827',
						color: 'white',
						width: '20px',
						height: '20px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '9999px',
						fontSize: '12px',
						fontWeight: '500',
					}}
				>
					{remainingNeededSignatureCount}
				</div>
			</div>
		);
	};

	return (
		<div className="flex justify-between items-center p-3 bg-white border-2">
			<div className="flex">
				{title && <h1 className="font-semibold">{title}</h1>}

				{declineReasonSubmitted && (
					<div className="ml-8" style={{ color: 'red' }}>
						Declined with the reason:{declineReasonSubmitted}
					</div>
				)}
			</div>

			<div className="flex flex-row-reverse items-center gap-4">
				<div>
					<select
						onChange={handleOtherActions}
						style={{
							height: '2.2rem',
							padding: '0 10px',
							borderRadius: '0.25rem',
						}}
					>
						<option selected>Other actions</option>
						<option value="download">Download</option>
						<option value="decline">Decline</option>
					</select>
				</div>

				{remainingNeededSignatureCount !== null &&
					remainingNeededSignatureCount > 0 && (
						<div style={{ marginRight: '10px' }}>
							<Button onClick={onClickNext}>Next</Button>
						</div>
					)}

				{remainingNeededSignatureCount === 0 && (
					<div style={{ marginRight: '10px' }}>
						<Button primary onClick={onClickSubmit}>
							Finish
						</Button>
					</div>
				)}

				{renderCounter()}
			</div>
		</div>
	);
};

export default EsignTools;
