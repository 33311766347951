import Router from 'preact-router';
import '@mystacks/theme/reset';
import Esign from './Esign';
import EsignDetails from './EsignDetails';
import '../style/index.css';
import '../assets/css/animation.css';

const App = () => {
	return (
		<div id="app">
			<Router>
				{/* @ts-expect-error */}
				<Esign path="/sign/e/:recipientFprint" />
				{/* @ts-expect-error */}
				<EsignDetails path="/sign/e/:signaturePackageFprint/packageDetailsView/" />
			</Router>
		</div>
	);
};

export default App;
