import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import cns from 'classnames';

import styles, { ANIMATION_DURATION } from './styles';
import { useEffect, useState } from 'preact/hooks';
import { FunctionComponent } from 'preact';

interface Props {
	disableClickCatcher: boolean;
	onCancel: () => void;
	show: boolean;
}

const Overlay: FunctionComponent<Props> = (props) => {
	const { disableClickCatcher, children, onCancel, show } = props;

	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		if (show) {
			setAnimate(true);
		} else {
			const timeout = setTimeout(() => {
				setAnimate(false);
			}, ANIMATION_DURATION);

			return () => {
				if (timeout) {
					clearTimeout(timeout);
				}
			};
		}
	}, [show]);

	return (
		<div
			className={cns(
				'fixed flex inset-0 z-[11] items-center justify-center',
				{
					'top-full left-full truncate': !animate,
				},
			)}
		>
			<div
				className={css(
					styles.overlayClickCatcher,
					show && styles.overlayClickCatcherShow,
				)}
				onClick={!disableClickCatcher ? onCancel : undefined}
			/>
			<div
				className={cns(
					'flex min-w-full h-screen sm:h-auto sm:min-w-[400px] sm:min-h-[150px] mx-0 my-auto bg-white rounded-md p-6 pb-[80px] sm:pb-6',
					css(
						!show && styles.overlayCardAnimationOut,
						show && styles.overlayCardAnimationIn,
					),
				)}
			>
				{!animate && !show ? null : children}
			</div>
		</div>
	);
};

Overlay.defaultProps = {
	show: false,
	disableClickCatcher: false,
};

export default Overlay;
