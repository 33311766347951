import { FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
// @ts-expect-error
import Button from '@mystacks/button';
// @ts-expect-error
import { TextInput } from '@mystacks/input';
import { css } from 'aphrodite';
import styles from './styles';

interface Props {
	title: string;
	message: string;
	primaryActionTitle: string;
	onCancel: () => void;
	onConfirm: (declineReason: string) => void;
}

const DeclineReasonInputModal: FunctionComponent<Props> = ({
	onCancel,
	onConfirm,
}) => {
	const [declineReason, setDeclineReason] = useState(null);

	return (
		<div className={css(styles.modalContainer)}>
			<p
				className={css(
					styles.modalMessage,
					styles.modalMessageCenterVertical,
				)}
			>
				Declining this document will clear anything you have entered and
				notify the sender.
			</p>

			<div className={css(styles.declineReasonInputContainer)}>
				<TextInput
					value={declineReason}
					label="Reason for declining"
					onChange={setDeclineReason}
					placeholder="Enter reason here"
				/>
			</div>

			<div
				id="modal-action-container"
				className={css(styles.modalActions)}
			>
				<Button cssStyle={styles.buttonAction} onClick={onCancel}>
					Cancel
				</Button>
				<Button
					disabled={declineReason ? false : true}
					onClick={() => onConfirm(declineReason!)}
					primary
				>
					Decline
				</Button>
			</div>
		</div>
	);
};

export default DeclineReasonInputModal;
