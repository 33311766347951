import { FunctionComponent } from 'preact';
// @ts-expect-error
import Button from '@mystacks/button';
import { css } from 'aphrodite';
import styles from './styles';

interface Props {
	title: string;
	message: string;
	primaryActionTitle: string;
	onCancel: () => void;
	onConfirm: () => void;
}

const ConfirmationModal: FunctionComponent<Props> = ({
	title,
	message,
	onCancel,
	primaryActionTitle,
	onConfirm,
}) => (
	<div className={css(styles.modalContainer)}>
		{title && <h1 className={css(styles.modalTitle)}>{title}</h1>}

		{message && (
			<p
				className={css(
					styles.modalMessage,
					styles.modalMessageCenterVertical,
				)}
			>
				{message}
			</p>
		)}

		<div id="modal-action-container" className={css(styles.modalActions)}>
			<Button cssStyle={styles.buttonAction} onClick={onCancel}>
				Cancel
			</Button>

			<Button cssStyle={styles.buttonAction} onClick={onConfirm} primary>
				{primaryActionTitle}
			</Button>
		</div>
	</div>
);

export default ConfirmationModal;
