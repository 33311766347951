import { StyleSheet } from 'aphrodite';
import env from '../../utils/env';

export default StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		paddingTop: '10px',
		justifyContent: 'space-between',
	},
	esignToolsContainer: {
		display: 'flex',
		padding: '10px',
	},
	esignToolsButtonContainer: {
		justifyContent: 'space-between',
	},
	successImageIcon: {
		background: `url(${env.s3BucketLawyawStaticUrl}/img/success-big.png) no-repeat top center;`,
		height: '50px',
		backgroundSize: 'contain',
	},
	cardContainer: {
		marginTop: '50px',
	},
	signatureHeader: {
		paddingTop: '10px',
		paddingBottom: '10px',
		fontSize: '20px',
		fontWeight: 400,
	},
	declineReasonInputContainer: {
		marginTop: '10px',
	},
	animationLogoContainer: {
		position: 'absolute',
		top: '25%',
		left: '50%',
	},
	animationLogoSvg: {
		position: 'relative',
		left: '-50%',
	},
});
