import cns from 'classnames';
import { FunctionComponent } from 'preact';
import { css } from 'aphrodite';
import styles from './EsignViewer/styles';

interface Props {}

const Card: FunctionComponent<Props> = ({ children }) => (
	<div className={cns(css(styles.cardContainer), 'flex')}>
		<div
			className="
				flex
				flex-col
				max-w-[500px]
				bg-white
				text-center
				px-2
				py-4
				sm:px-10
				sm:py-5
				height-[100px]
				align-items-center
				mr-[5px]
				ml-[5px]
				border
				border-solid
				border-gray-300
				sm:mr-auto
				sm:ml-auto
		"
		>
			{children}
		</div>
	</div>
);

export default Card;
