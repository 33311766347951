import { createRef, FunctionComponent } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { createWebViewer, Sign } from '@mystacksco/hancock';
import { NeededSignature } from '@mystacksco/hancock/dist/sign/schema';
import { SignedEvent } from '@mystacksco/hancock/dist/sign/events';
import EsignTools from '../EsignViewer/EsignTools';
import Modal, { LAYOUT_MODAL_TYPES } from '../Modal';
import {
	getSignaturePackageDetails,
	uploadUserSignatureImage,
	submitSignaturePackage,
	declineSignaturePackage,
} from '../../services/esign';
import Card from '../Card';
import { css } from 'aphrodite';
import styles from './styles';
import useModal from '../../hooks/useModal';
import env from '../../utils/env';
import { BASE_64, HEIGHT, WIDTH } from '../../hooks/clickHereToSignImageBase64';
import { makeDocumentsForHancock } from '../../utils/hancock';
import { SignPackageState } from '../../types';

interface Props {
	recipientFprint: string;
}

const EsignViewer: FunctionComponent<Props> = ({ recipientFprint }) => {
	const viewerRef = useRef<HTMLDivElement | null>(null);
	const [state, _setState] = useState<SignPackageState | null>(null);
	const setState = (nextState: SignPackageState) =>
		_setState((prevState) => ({ ...prevState, ...nextState }));
	const [isLoading, setIsLoading] = useState(true);
	const hancock = useRef<Sign | null>(null);
	const [remainingNeededSignatureCount, setRemainingNeededSignatureCount] =
		useState<number | null>(null);
	const currentUserSignatureImage = useRef<Blob | string | null>(null);
	const [signedNeededSignatures, setSignedNeededSignatures] = useState<
		Pick<NeededSignature, 'id' | 'height' | 'width'>[]
	>([]);

	const onSigned: SignedEvent = (
		neededSignature,
		signature,
		annotationMetadata,
	) => {
		const { xfdf, png } = signature;
		setRemainingNeededSignatureCount(
			hancock.current!.remainingNeededSignatureCount,
		);
		setSignedNeededSignatures((current) => {
			const newValue = [...current];
			const { width, height } = annotationMetadata;
			const { id } = neededSignature;
			newValue.push({ id: id!, width, height });
			return newValue;
		});
		// already cached the signature, no need to do so again
		if (currentUserSignatureImage.current) return;
		if (state!.shouldSaveSignatureAsPng) {
			currentUserSignatureImage.current = png;
		} else {
			currentUserSignatureImage.current = xfdf;
		}
	};

	useEffect(() => {
		// No package to render yet or package is invalid:
		if (!state) return;
		if (!state.signaturePackage || state.isPackageValid === false) return;
		// No DOM element available yet;
		if (!viewerRef.current) return;
		// Already loaded hancock/the package;
		if (hancock.current) return;
		createWebViewer(
			viewerRef.current,
			env.apiBaseUrl! + env.webviewerPath!,
			env.pdftronLicenseKey!,
		).then(async (webviewer) => {
			hancock.current = new Sign(webviewer, {
				clickToSignAnnotation: {
					base64: BASE_64,
					width: WIDTH,
					height: HEIGHT,
				},
			});
			const { signaturePackage, currentRecipientEmail } = state;
			const packageData = makeDocumentsForHancock(
				signaturePackage!,
				currentRecipientEmail!,
			);
			await hancock.current.loadDocuments(packageData);
			setRemainingNeededSignatureCount(
				hancock.current.remainingNeededSignatureCount,
			);
			hancock.current.on('signed', onSigned);
			setIsLoading(false);
		});
	}, [state, viewerRef, onSigned]);

	const { modalActive, modalType, modalProps, showModal, hideModal } =
		useModal();

	const [showSubmissionSuccessful, setShowSubmissionSuccessful] =
		useState(false);
	const [showDeclineConfirmation, setShowDeclineConfirmation] =
		useState(false);
	const [hideIsLoadingScreen, setHideIsLoadingScreen] = useState(false);
	const [declineReasonSubmitted, setDeclineReasonSubmitted] = useState<
		string | null
	>(null);
	const [declineReason, setDeclineReason] = useState<string | null>(null);

	const handleSubmitSignature = async () => {
		const currentUserSignatureImageType = state!.shouldSaveSignatureAsPng
			? 'png'
			: 'xfdf';
		await uploadUserSignatureImage({
			signatureImageBlob: currentUserSignatureImage.current,
			recipientFprint,
			signatureImageType: currentUserSignatureImageType,
		});
		const signatureElements = signedNeededSignatures.map((each) => {
			const { id, width, height } = each;
			return {
				id,
				image_width: width,
				image_height: height,
				width,
				height,
			};
		});
		await submitSignaturePackage({ recipientFprint, signatureElements });
		setShowSubmissionSuccessful(true);
	};

	const handleDeclineSignature = () => {
		showModal(LAYOUT_MODAL_TYPES.declineReasonInput, {
			onConfirm: (declineReason) => {
				onConfirmDecline(declineReason!);
				hideModal();
			},
			primaryActionTitle: 'Confirm',
			// should not be rendered in that modal
			message: '',
			// should not be rendered in that modal
			title: '',
		});
	};

	const onConfirmDecline = async (declineReason: string) => {
		await declineSignaturePackage({ recipientFprint, declineReason });
		setDeclineReasonSubmitted(declineReason);
		setDeclineReason(declineReason);
		setShowDeclineConfirmation(true);
	};

	const handleSubmitShowConfirmation = () => {
		showModal(LAYOUT_MODAL_TYPES.confirm, {
			message:
				'Do you agree that the submitted signature is a legal representation of your signature?',
			onConfirm: () => {
				handleSubmitSignature();
				hideModal();
			},
			primaryActionTitle: 'Confirm',
			// should not be rendered in that modal
			title: '',
		});
	};

	const handleDownloadPdf = async () => {
		await hancock.current!.download();
	};

	useEffect(() => {
		getSignaturePackageDetails().then(
			({
				signaturePackage,
				currentRecipientEmail,
				isPackageValid,
				shouldSaveSignatureAsPng,
			}) => {
				setState({
					signaturePackage: signaturePackage!,
					currentRecipientEmail,
					isPackageValid,
					shouldSaveSignatureAsPng,
				});
				if (!isPackageValid) {
					setHideIsLoadingScreen(true);
				}
			},
		);
	}, []);

	const goToNextSignature = () => {
		hancock.current!.scrollToNextNeededSignature();
	};

	return (
		<>
			{isLoading && !hideIsLoadingScreen && (
				<div className={css(styles.animationLogoContainer)}>
					<svg
						className={css(styles.animationLogoSvg)}
						xmlns="http://www.w3.org/1000/svg"
						width="188px"
						height="178px"
						viewBox="0 0 188 178"
						version="1.1"
					>
						<title>Loading Quill</title>
						<defs />
						<g
							id="Page-1"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<g
								id="Group"
								transform="translate(3.000000, 3.000000)"
								stroke="#2962FF"
								strokeWidth="6"
							>
								<path
									id="Path"
									d="M182,172L0,170.236872L121.10815,57.0540317L122.30406,0.321521379L179.331455,0.321521379L179.593836,57.0540317L104.610699,132.610526L41.2258984,132.610526L41.2258984,73.162444L121.390507,0.321521379"
									className="EVdVYZPt_0"
								/>
								<path
									d="M122,56 L177,56"
									id="Path-2"
									className="EVdVYZPt_1"
								/>
								<path
									d="M69,49 L69,106"
									id="Path-3"
									className="EVdVYZPt_2"
								/>
								<path
									d="M69,106 L131,106"
									id="Path-4"
									className="EVdVYZPt_3"
								/>
							</g>
						</g>
					</svg>
				</div>
			)}

			{state?.isPackageValid === false && (
				<Card>
					<p className={css(styles.signatureHeader)}>
						The signature request is no longer valid.
					</p>
				</Card>
			)}
			<div className="h-full" style={{ opacity: isLoading ? 0.4 : 1 }}>
				{state?.isPackageValid &&
					!showDeclineConfirmation &&
					!showSubmissionSuccessful && (
						<>
							<div className="h-full flex flex-col pb-[70px] md:pb-0">
								<EsignTools
									title={state.signaturePackage?.title}
									remainingNeededSignatureCount={
										remainingNeededSignatureCount
									}
									goToNextSignature={goToNextSignature}
									handleSubmitSignature={
										handleSubmitShowConfirmation
									}
									handleDownloadPdf={handleDownloadPdf}
									handleDeclineSignature={
										handleDeclineSignature
									}
									declineReasonSubmitted={
										declineReasonSubmitted
									}
								/>

								<div
									ref={viewerRef}
									id="viewer"
									style={{
										width: '100%',
										flexGrow: 1,
										backgroundColor: '#f1f3f5',
										borderTop: '1px solid #dededf',
									}}
								/>
							</div>
						</>
					)}

				{!isLoading && showDeclineConfirmation && (
					<Card>
						<p className={css(styles.signatureHeader)}>
							Signature Package Declined with following reason:
						</p>
						<p>{declineReason}</p>
					</Card>
				)}

				{!isLoading && showSubmissionSuccessful && (
					<Card>
						<div className={css(styles.successImageIcon)}></div>
						<p className={css(styles.signatureHeader)}>
							Signatures submitted successfully
						</p>
						<p>
							You will receive a preview link of the documents in
							your email once all parties have signed the package.
						</p>
					</Card>
				)}
			</div>

			<Modal
				modalActive={modalActive}
				modalType={modalType}
				modalProps={modalProps!}
				// @ts-expect-error
				showModal={showModal}
				hideModal={hideModal}
			/>
		</>
	);
};

export default EsignViewer;
