import moment from 'moment';
import { Element, SignaturePackage } from '../types';
import { getIsLegacyProXLegacyAttorneyElement } from './documents';

class DateElementIncluder {
	constructor(
		private signaturePackage: SignaturePackage,
		private currentRecipientEmail: string,
	) {}

	getIsLegacyElement = (element: Element) => {
		const { whoSigns, isUnifiedEsign, lawyawDocuments } =
			this.signaturePackage;
		return getIsLegacyProXLegacyAttorneyElement(
			element,
			whoSigns,
			isUnifiedEsign,
		);
	};

	// Date that was submitted by another person (not this recipient)...
	getIsSubmittedBySomeoneElse = (element: Element) => {
		if (
			element.inputType === 1 &&
			moment(element.value).isValid() &&
			element.recipient?.submitted
		)
			return true;

		if (element.inputType === 6 && element.recipient?.submitted)
			return true;

		return false;
	};

	// Dates that are assigned to 'me' and should be dated now
	getIsMine = (element: Element) =>
		element.inputType === 6 &&
		element.recipient?.email === this.currentRecipientEmail;
}

export default DateElementIncluder;
