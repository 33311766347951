/* Components */
import ModalOverlay from './Overlay';
import ConfirmationModal from './ConfirmationModal';
import DeclineReasonInputModal from './DeclineReasonInputModal';
import { FunctionComponent } from 'preact';

export const LAYOUT_MODAL_TYPES = {
	confirm: 'confirm',
	declineReasonInput: 'declineReasonInput',
};

export const MODALS = {
	[LAYOUT_MODAL_TYPES.confirm]: ConfirmationModal,
	[LAYOUT_MODAL_TYPES.declineReasonInput]: DeclineReasonInputModal,
};

interface ModalProps {
	onCancel?: () => void;
	title: string;
	message: string;
	primaryActionTitle: string;
	disableClickCatcher?: boolean;
}

interface Props {
	modalActive: boolean;
	modalType?: string | null;
	hideModal: () => void;
	showModal: (modalTypeToShow: string, props: ModalProps) => void;
	modalProps: ModalProps;
}

const Modals: FunctionComponent<Props> = (props) => {
	const { modalActive, hideModal, modalProps, modalType } = props;

	if (!modalType) return null;

	const ModalChild = MODALS[modalType] ? MODALS[modalType] : null;

	const handleCancel = () => {
		if (modalProps.onCancel) {
			modalProps.onCancel();
		}
		hideModal();
	};

	return (
		<ModalOverlay
			show={modalActive}
			onCancel={handleCancel}
			disableClickCatcher={modalProps.disableClickCatcher ?? false}
		>
			{ModalChild && (
				<ModalChild
					onCancel={hideModal}
					onConfirm={hideModal}
					{...modalProps}
				/>
			)}
		</ModalOverlay>
	);
};

export default Modals;
