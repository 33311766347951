import { AuditTrailItem } from '../types';
import { Core } from '@pdftron/webviewer';
import moment from 'moment';

const renderAuditTrail = (
	packageTitle: string,
	packageId: string,
	auditTrail: AuditTrailItem[],
	Annotations: typeof Core.Annotations,
) => {
	const annotations: Core.Annotations.Annotation[] = [];
	const lock = true;
	const fontSize = '15pt';
	const initialTextTop = 25;
	const textLeft = 15;
	const font = 'Helvetica-Bold';

	const title = new Annotations.FreeTextAnnotation();
	title.Locked = lock;
	title.StrokeThickness = 0;
	title.Font = font;
	title.FontSize = fontSize;
	title.X = textLeft;
	title.Y = initialTextTop;
	title.Width = 150;
	title.Height = 15;
	title.setContents('Title:');
	title.TextColor = new Annotations.Color(0, 0, 0);
	annotations.push(title);

	const titleValue = new Annotations.FreeTextAnnotation();
	titleValue.Locked = lock;
	titleValue.StrokeThickness = 0;
	titleValue.Font = font;
	titleValue.FontSize = fontSize;
	titleValue.X = textLeft;
	titleValue.Y = initialTextTop * 2;
	titleValue.Width = 500;
	titleValue.Height = 20;
	titleValue.PageNumber = 8;
	titleValue.TextColor = new Annotations.Color(0, 0, 0);
	titleValue.setContents(packageTitle);
	annotations.push(titleValue);

	const packageIdLabel = new Annotations.FreeTextAnnotation();
	packageIdLabel.StrokeThickness = 0;
	packageIdLabel.Font = font;
	packageIdLabel.FontSize = fontSize;
	packageIdLabel.X = textLeft;
	packageIdLabel.Y = initialTextTop * 3 + 10;
	packageIdLabel.Width = 150;
	packageIdLabel.Height = 25;
	packageIdLabel.TextColor = new Annotations.Color(0, 0, 0);
	packageIdLabel.setContents('Package ID:');
	packageIdLabel.Locked = lock;
	annotations.push(packageIdLabel);

	const packageIdValue = new Annotations.FreeTextAnnotation();
	packageIdValue.Locked = lock;
	packageIdValue.StrokeThickness = 0;
	packageIdValue.Font = font;
	packageIdValue.FontSize = fontSize;
	packageIdValue.X = textLeft;
	packageIdValue.Y = initialTextTop * 4 + 10;
	packageIdValue.Width = 500;
	packageIdValue.Height = 20;
	packageIdValue.TextColor = new Annotations.Color(0, 0, 0);
	packageIdValue.setContents(packageId);
	annotations.push(packageIdValue);

	const auditTrailFontSize = '12pt';
	const auditTrailTableHeaderTop = initialTextTop * 5 + 20;
	const auditTrailTableHeaderTopPadding = 12;
	const auditTrailTableHeaderHeight = 40;

	const auditTrailTableHeader = new Annotations.FreeTextAnnotation();
	auditTrailTableHeader.Locked = lock;
	auditTrailTableHeader.StrokeThickness = 0;
	auditTrailTableHeader.Font = font;
	auditTrailTableHeader.FontSize = fontSize;
	auditTrailTableHeader.X = 1;
	auditTrailTableHeader.Y = auditTrailTableHeaderTop;
	auditTrailTableHeader.Width = 1000;
	auditTrailTableHeader.Height = auditTrailTableHeaderHeight;
	auditTrailTableHeader.setContents('');
	auditTrailTableHeader.FillColor = new Annotations.Color(0, 255, 255);
	annotations.push(auditTrailTableHeader);

	const actionWidth = 70;
	const auditTrailTableHeaderAction = new Annotations.FreeTextAnnotation();
	auditTrailTableHeaderAction.Locked = lock;
	auditTrailTableHeaderAction.StrokeThickness = 0;
	auditTrailTableHeaderAction.Font = font;
	auditTrailTableHeaderAction.FontSize = auditTrailFontSize;
	auditTrailTableHeaderAction.X = textLeft;
	auditTrailTableHeaderAction.Y =
		auditTrailTableHeaderTop + auditTrailTableHeaderTopPadding;
	auditTrailTableHeaderAction.Width = actionWidth;
	auditTrailTableHeaderAction.Height = 15;
	auditTrailTableHeaderAction.setContents('ACTION');
	auditTrailTableHeaderAction.FillColor = new Annotations.Color(0, 255, 255);
	auditTrailTableHeaderAction.TextColor = new Annotations.Color(47, 79, 79);
	annotations.push(auditTrailTableHeaderAction);

	const recipientWidth = 275;
	const auditTrailTableHeaderRecipient = new Annotations.FreeTextAnnotation();
	auditTrailTableHeaderRecipient.Locked = lock;
	auditTrailTableHeaderRecipient.StrokeThickness = 0;
	auditTrailTableHeaderRecipient.Font = font;
	auditTrailTableHeaderRecipient.FontSize = auditTrailFontSize;
	auditTrailTableHeaderRecipient.X = textLeft + actionWidth;
	auditTrailTableHeaderRecipient.Y =
		auditTrailTableHeaderTop + auditTrailTableHeaderTopPadding;
	auditTrailTableHeaderRecipient.Width = recipientWidth;
	auditTrailTableHeaderRecipient.Height = 15;
	auditTrailTableHeaderRecipient.setContents('RECIPIENT');
	auditTrailTableHeaderRecipient.FillColor = new Annotations.Color(
		0,
		255,
		255,
	);
	auditTrailTableHeaderRecipient.TextColor = new Annotations.Color(
		47,
		79,
		79,
	);
	annotations.push(auditTrailTableHeaderRecipient);

	const timeWidth = 180;
	const auditTrailTableHeaderTime = new Annotations.FreeTextAnnotation();
	auditTrailTableHeaderTime.Locked = lock;
	auditTrailTableHeaderTime.StrokeThickness = 0;
	auditTrailTableHeaderTime.Font = font;
	auditTrailTableHeaderTime.FontSize = auditTrailFontSize;
	auditTrailTableHeaderTime.X = textLeft + actionWidth + recipientWidth;
	auditTrailTableHeaderTime.Y =
		auditTrailTableHeaderTop + auditTrailTableHeaderTopPadding;
	auditTrailTableHeaderTime.Width = timeWidth;
	auditTrailTableHeaderTime.Height = 15;
	auditTrailTableHeaderTime.setContents('TIME');
	auditTrailTableHeaderTime.FillColor = new Annotations.Color(0, 255, 255);
	auditTrailTableHeaderTime.TextColor = new Annotations.Color(47, 79, 79);
	annotations.push(auditTrailTableHeaderTime);

	const ipWidth = 70;
	const auditTrailTableHeaderIp = new Annotations.FreeTextAnnotation();
	auditTrailTableHeaderIp.Locked = lock;
	auditTrailTableHeaderIp.StrokeThickness = 0;
	auditTrailTableHeaderIp.Font = font;
	auditTrailTableHeaderIp.FontSize = auditTrailFontSize;
	auditTrailTableHeaderIp.X =
		textLeft + actionWidth + recipientWidth + timeWidth;
	auditTrailTableHeaderIp.Y =
		auditTrailTableHeaderTop + auditTrailTableHeaderTopPadding;
	auditTrailTableHeaderIp.Width = ipWidth;
	auditTrailTableHeaderIp.Height = 15;
	auditTrailTableHeaderIp.setContents('IP');
	auditTrailTableHeaderIp.FillColor = new Annotations.Color(0, 255, 255);
	auditTrailTableHeaderIp.TextColor = new Annotations.Color(47, 79, 79);
	annotations.push(auditTrailTableHeaderIp);

	let initialRowSpacing = auditTrailTableHeaderHeight;

	auditTrail.forEach((row) => {
		const eachRowValueTop =
			auditTrailTableHeaderTop +
			initialRowSpacing +
			auditTrailTableHeaderTopPadding;
		const rowBackground = new Annotations.FreeTextAnnotation();
		rowBackground.Locked = lock;
		rowBackground.StrokeThickness = 0;
		rowBackground.Font = font;
		rowBackground.FontSize = auditTrailFontSize;
		rowBackground.StrokeThickness = 0.1;
		rowBackground.X = 1;
		rowBackground.Y = eachRowValueTop - auditTrailTableHeaderTopPadding;
		rowBackground.Width = 1000;
		rowBackground.Height = 40;
		rowBackground.setContents('');
		annotations.push(rowBackground);

		const rowActionValue = new Annotations.FreeTextAnnotation();
		rowActionValue.Locked = lock;
		rowActionValue.StrokeThickness = 0;
		rowActionValue.Font = font;
		rowActionValue.FontSize = auditTrailFontSize;
		rowActionValue.X = textLeft;
		rowActionValue.Y = eachRowValueTop;
		rowActionValue.Width = actionWidth;
		rowActionValue.Height = 15;
		rowActionValue.TextColor = new Annotations.Color(0, 0, 0);
		rowActionValue.setContents(row.actionText);
		annotations.push(rowActionValue);

		const recipientValue = `${row.recipient.name} (${row.recipient.email})`;
		const rowRecipientValue = new Annotations.FreeTextAnnotation();
		rowRecipientValue.Locked = lock;
		rowRecipientValue.StrokeThickness = 0;
		rowRecipientValue.Font = font;
		rowRecipientValue.FontSize = auditTrailFontSize;
		rowRecipientValue.X = textLeft + actionWidth;
		rowRecipientValue.Y = eachRowValueTop;
		rowRecipientValue.Width = recipientWidth;
		rowRecipientValue.Height = 15;
		rowRecipientValue.TextColor = new Annotations.Color(0, 0, 0);
		rowRecipientValue.setContents(recipientValue);
		annotations.push(rowRecipientValue);

		const timestampValue = moment(row.timestamp).format(
			'YYYY-MM-DD HH:mm:ss Z',
		);
		const rowTimeValue = new Annotations.FreeTextAnnotation();
		rowTimeValue.Locked = lock;
		rowTimeValue.StrokeThickness = 0;
		rowTimeValue.Font = font;
		rowTimeValue.FontSize = auditTrailFontSize;
		rowTimeValue.X = textLeft + actionWidth + recipientWidth;
		rowTimeValue.Y = eachRowValueTop;
		rowTimeValue.Width = timeWidth;
		rowTimeValue.Height = 15;
		rowTimeValue.TextColor = new Annotations.Color(0, 0, 0);
		rowTimeValue.setContents(timestampValue);
		annotations.push(rowTimeValue);

		const rowIpValue = new Annotations.FreeTextAnnotation();
		rowIpValue.Locked = lock;
		rowIpValue.StrokeThickness = 0;
		rowIpValue.Font = font;
		rowIpValue.FontSize = auditTrailFontSize;
		rowIpValue.X = textLeft + actionWidth + recipientWidth + timeWidth;
		rowIpValue.Y = eachRowValueTop;
		rowIpValue.Width = ipWidth;
		rowIpValue.Height = 15;
		rowIpValue.TextColor = new Annotations.Color(0, 0, 0);
		rowIpValue.setContents(row.ipAddress);
		annotations.push(rowIpValue);

		initialRowSpacing += 40.1;
	});

	const securedTrailText = new Annotations.FreeTextAnnotation();
	securedTrailText.Locked = lock;
	securedTrailText.StrokeThickness = 0;
	securedTrailText.Font = font;
	securedTrailText.FontSize = '10pt';
	securedTrailText.X = 206;
	securedTrailText.Y = 5;
	securedTrailText.Width = 300;
	securedTrailText.Height = 15;
	securedTrailText.TextColor = new Annotations.Color(0, 0, 0);
	securedTrailText.setContents('Secured audit trail verified by Lawyaw');
	annotations.push(securedTrailText);

	return annotations;
};

export default renderAuditTrail;
