import cookieUtil from '../utils/cookies';
import env from '../utils/env';

const httpRequests = ({ apiUrl }) => {
	async function innerSend({
		host = apiUrl,
		relativePath,
		method,
		body = null,
		customRequestUrl = null,
	}) {
		const requestUrl = customRequestUrl
			? customRequestUrl
			: `${host}${relativePath}`;

		let response;
		let headers = cookieUtil.getHeaderTokens();
		if (body) {
			headers['Content-Type'] = 'application/json';
			body = JSON.stringify(body);
		}

		try {
			response = await fetch(requestUrl, {
				body,
				headers,
				credentials: 'include',
				method,
			});

			if (response.status === 405) {
				window.location.reload();
			} else if (response.status >= 300) {
				throw new Error(`${response.status}`);
			}
		} catch (err) {
			console.error(`Error requesting ${requestUrl}`, err);
			throw err;
		}

		return response;
	}

	async function formUpload({
		host = apiUrl,
		parse = false,
		relativePath,
		data,
	}) {
		const requestUrl = `${host}${relativePath}`;
		let response;

		let headers = cookieUtil.getHeaderTokens();

		try {
			response = await fetch(requestUrl, {
				headers,
				method: 'POST',
				body: data,
				credentials: 'include',
			});
		} catch (err) {
			console.error(`Error uploading ${requestUrl}`, err);
			throw err;
		}

		return parse ? await response.json() : response;
	}

	async function innerSendAndParse(params) {
		const response = await innerSend({
			host: apiUrl,
			...params,
		});
		return await response.json();
	}

	return {
		innerSend,
		innerSendAndParse,
		formUpload,
	};
};

export const http = httpRequests({
	apiUrl: env.apiUrl,
});
