import { useState } from 'preact/hooks';

interface ModalProps {
	title: string;
	primaryActionTitle: string;
	message: string;
	onConfirm: (reason?: string) => void;
}

const useModal = () => {
	const [modalActive, setModalActive] = useState(false);
	const [modalProps, setModalProps] = useState<ModalProps | null>(null);
	const [modalType, setModalType] = useState<string | null>(null);

	const showModal = (modalTypeToShow: string, props: ModalProps) => {
		setModalType(modalTypeToShow);
		setModalProps(props);
		setModalActive(true);
	};

	const hideModal = () => {
		setModalActive(false);
	};

	return {
		modalActive,
		modalType,
		modalProps,
		showModal,
		hideModal,
	};
};

export default useModal;
