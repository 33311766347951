import { useState, useEffect, useRef } from 'preact/hooks';
import { css } from 'aphrodite';
// @ts-expect-error
import Button from '@mystacks/button';
import { createWebViewer, Read } from '@mystacksco/hancock';
import { Core } from '@pdftron/webviewer';
import moment from 'moment';
import { getSignaturePackageDetails } from '../../services/esign';
import styles from './styles';
import {
	getPackageIdAndPosition,
	makeReadOnlyDocumentsForHancock,
} from '../../utils/hancock';
import env from '../../utils/env';
import renderAuditTrail from '../../utils/auditTrail';
import { ReadPackageState } from '../../types';

const EsignDetailsViewer = () => {
	const hancock = useRef<Read | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [hideIsLoadingScreen, setHideIsLoadingScreen] = useState(false);
	const viewerRef = useRef<HTMLDivElement | null>(null);
	const [state, _setState] = useState<ReadPackageState | null>(null);
	const setState = (nextState: ReadPackageState) =>
		_setState((prevState) => ({ ...prevState, ...nextState }));

	useEffect(() => {
		getSignaturePackageDetails().then(
			({ signaturePackage, currentRecipientEmail, isDetailsView }) => {
				setState({
					signaturePackage: signaturePackage!,
					currentRecipientEmail,
					isDetailsView,
				});
				if (!isDetailsView) {
					setHideIsLoadingScreen(true);
				}
			},
		);
	}, []);

	useEffect(() => {
		if (!state) return;
		// No package to render yet or package is invalid:
		if (!state.signaturePackage) return;
		// No DOM element available yet;
		if (!viewerRef.current) return;
		// Already loaded hancock/the package;
		if (hancock.current) return;
		createWebViewer(
			viewerRef.current,
			env.apiBaseUrl! + env.webviewerPath!,
			env.pdftronLicenseKey!,
		).then(async (webviewer) => {
			hancock.current = new Read(webviewer);
			const { signaturePackage, currentRecipientEmail } = state;
			const packageData = makeReadOnlyDocumentsForHancock(
				signaturePackage!,
				currentRecipientEmail!,
			);
			await hancock.current.loadDocuments(packageData);
			const packageId = getPackageIdAndPosition(signaturePackage!);
			hancock.current.renderPackageId(packageId);
			setIsLoading(false);
			if (!state.signaturePackage.auditTrail) return;
			hancock.current.renderAdditionalPage((Annotations) =>
				renderAuditTrail(
					state.signaturePackage.title,
					state.signaturePackage.fprint,
					state.signaturePackage.auditTrail!,
					Annotations,
				),
			);
		});
	}, [state, viewerRef]);

	const handleDownloadPdf = async () => {
		await hancock.current!.download();
	};

	return (
		<>
			{isLoading && !hideIsLoadingScreen && (
				<div className={css(styles.animationLogoContainer)}>
					<svg
						className={css(styles.animationLogoSvg)}
						xmlns="http://www.w3.org/1000/svg"
						width="188px"
						height="178px"
						viewBox="0 0 188 178"
						version="1.1"
					>
						<title>Loading Quill</title>
						<defs />
						<g
							id="Page-1"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<g
								id="Group"
								transform="translate(3.000000, 3.000000)"
								stroke="#2962FF"
								strokeWidth="6"
							>
								<path
									id="Path"
									d="M182,172L0,170.236872L121.10815,57.0540317L122.30406,0.321521379L179.331455,0.321521379L179.593836,57.0540317L104.610699,132.610526L41.2258984,132.610526L41.2258984,73.162444L121.390507,0.321521379"
									className="EVdVYZPt_0"
								/>
								<path
									d="M122,56 L177,56"
									id="Path-2"
									className="EVdVYZPt_1"
								/>
								<path
									d="M69,49 L69,106"
									id="Path-3"
									className="EVdVYZPt_2"
								/>
								<path
									d="M69,106 L131,106"
									id="Path-4"
									className="EVdVYZPt_3"
								/>
							</g>
						</g>
					</svg>
				</div>
			)}

			<div style={{ opacity: isLoading ? 0.4 : 1 }}>
				{state?.isDetailsView && (
					<>
						<div className="flex p-5">
							<div className="grow">
								<div className="text-base font-bold">
									{state.signaturePackage.title}
								</div>
								<div className="text-xs mt-1">
									Package ID: {state.signaturePackage.fprint}
								</div>
							</div>

							<div className="grow-0">
								<Button onClick={handleDownloadPdf}>
									Download
								</Button>
							</div>
						</div>

						<div className="flex" style={{ height: '93.8vh' }}>
							<div
								ref={viewerRef}
								id="viewer"
								style={{ width: '100%' }}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default EsignDetailsViewer;
