import { StyleSheet } from 'aphrodite';
import theme from '../../theme';
import env from '../../utils/env';

export const ANIMATION_DURATION = 400;

const showHide = {
	'0%': {
		top: '0%',
		left: '0%',
	},
	'100%': {
		top: '100%',
		left: '100%',
	},
};

export default StyleSheet.create({
	overlayClickCatcher: {
		position: 'absolute',
		top: -30,
		left: -30,
		right: -30,
		bottom: -30,
		background: 'rgba(48,53,58, 0.125)',
		transition: 'background 0.5s ease',
		transform: 'translate3d(0px,0px,-1)',
		zIndex: -1,
	},
	overlayClickCatcherShow: {
		background: 'rgba(48,53,58, 0.5)',
		':hover': {
			cursor: 'pointer',
		},
	},
	slideInOverlayContainerHiddenDelay: {
		animationName: showHide,
		animationDuration: 0,
		animationDelay: `${ANIMATION_DURATION}ms`,
		animationFillMode: 'forwards',
	},
	overlayCard: {
		display: 'flex',
		minWidth: 400,
		margin: '0 auto',
		minHeight: 150,
		background: theme.surface,
		borderRadius: theme.borderRadius,
		padding: theme.unit * 3,
		boxShadow: '0 7px 24px 0 rgba(28,34,45,0.24)',
	},
	overlayCardAnimationOut: {
		// animationName: fadeOutDownBig,
		animationDuration: `${ANIMATION_DURATION * 1.5}ms`,
		animationFillMode: 'forwards',
	},
	overlayCardAnimationIn: {
		// animationName: fadeInUpBig,
		animationDuration: `${ANIMATION_DURATION}ms`,
		animationFillMode: 'backwards',
	},
	buttonAction: {
		marginRight: theme.unit * 2,
		':last-of-type': {
			marginRight: 0,
		},
	},
	modalContainer: {
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		minWidth: 400,
	},
	modalContainerLarge: {
		width: 760,
	},
	modalBody: {
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		lineHeight: '1.25em',
		fontSize: theme.fonts.size.small,
	},
	modalBodySmallMargin: {
		marginTop: '18px',
	},
	modalTitle: {
		marginBottom: theme.unit * 3,
		fontWeight: 'bold',
		fontSize: theme.fonts.size.regular,
	},
	modalSubtitle: {
		fontWeight: 'normal',
		color: theme.colors.chrome,
		fontSize: theme.fonts.size.small,
	},
	modalMessage: {
		fontSize: theme.fonts.size.small,
		flexGrow: 1,
		lineHeight: '1.5em',
		color: theme.colors.charcoal,
		marginBottom: theme.unit,
	},
	modalMessageCenterVertical: {
		display: 'flex',
		alignItems: 'center',
	},
	modalActionsLeft: {
		marginTop: theme.unit * 4,
		display: 'flex',
		justifyContent: 'flex-start',
	},
	modalActions: {
		marginTop: theme.unit * 4,
	},
	modalActionsNoMargin: {
		marginTop: 0,
	},
	modalRow: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.unit * 5,
		userSelect: 'none',
	},
	modalRowSmall: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.unit * 2,
		userSelect: 'none',
	},
	/* Cancel Subscription */
	cancelSubscriptionModalAcknowledgeInput: {
		marginRight: theme.unit,
	},
	cancelSubscriptionModalAcknowledge: {
		display: 'flex',
		fontSize: theme.fonts.size.small,
		flexGrow: 1,
		lineHeight: '1.5em',
		color: theme.colors.charcoal,
		margin: `${theme.unit * 4}px 0`,
		marginBottom: theme.unit * 5,
		fontWeight: 500,
	},
	downloadDocumentModalShowHighlightInput: {
		marginRight: theme.unit,
	},
	inviteTeamMemberModalEmailLabel: {
		marginBottom: `${theme.unit}px !important`,
	},
	/* Create Subscription Modal */
	modalCreateSubscriptionDiscount: {
		color: theme.colors.tennisBall,
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.unit * 5,
		fontSize: theme.fonts.size.small,
		userSelect: 'none',
	},
	modalCreateSubscriptionDueToday: {
		color: theme.colors.bluebird,
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.unit * 5,
		fontSize: theme.fonts.size.small,
		userSelect: 'none',
	},
	buttonContainer: {
		display: 'flex',
		paddingTop: '10px',
		justifyContent: 'space-between',
	},
	esignToolsContainer: {
		display: 'flex',
		padding: '10px',
	},
	esignToolsButtonContainer: {
		justifyContent: 'space-between',
	},
	successImageIcon: {
		background: `url(${env.s3BucketLawyawStaticUrl}/img/success-big.png) no-repeat top center;`,
		height: '50px',
		backgroundSize: 'contain',
	},
	signatureHeader: {
		paddingTop: '10px',
		paddingBottom: '10px',
		fontSize: '20px',
		fontWeight: 400,
	},
	animationLogoContainer: {
		position: 'absolute',
		top: '25%',
		left: '50%',
	},
	animationLogoSvg: {
		position: 'relative',
		left: '-50%',
	},
	declineReasonInputContainer: {
		marginTop: '10px',
	},
});
