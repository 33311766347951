import { FunctionComponent } from 'preact';
import EsignViewer from '../components/EsignViewer';

interface Props {
	recipientFprint: string;
}

const Esign: FunctionComponent<Props> = ({ recipientFprint }) => {
	return <EsignViewer recipientFprint={recipientFprint} />;
};
export default Esign;
