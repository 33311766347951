import moment from 'moment';
import { SignDocuments } from '@mystacksco/hancock/dist';
import {
	Date,
	NeededSignature,
	Signature,
} from '@mystacksco/hancock/dist/sign/schema';
import { Documents as ReadDocuments } from '@mystacksco/hancock/dist/read/schema';
import { Document, SignaturePackage, WhoSigns } from '../types';
import {
	calcMagicalXPositionAdjustment,
	calcMagicalYPositionAdjustment,
	getIsLegacyProXLegacyAttorneyElement,
	getIsRecipientSignature,
	getIsSubmittedSignature,
	getShouldDoMagicalPositionAdjustment,
} from './documents';
import DateElementIncluder from './dateElementIncluder';

export const isNum = (s: string | number | undefined) =>
	s !== undefined && s !== null && !isNaN(Number(s));

/**
 * Extracts signatures that were submitted by "other" recipients (IE not the
 * current recipient) from the signature package.
 *
 * This function returns page numbers for a combined document.
 */
export const extractSubmittedSignatures = (
	signaturePackage: SignaturePackage,
	currentRecipientEmail: string,
): Signature[] => {
	const { whoSigns, isUnifiedEsign, lawyawDocuments } = signaturePackage;
	const submittedSignatures: Signature[] = [];

	let currentPageNumber = 1;
	lawyawDocuments.forEach((document) => {
		document.pages.forEach((page) => {
			page.elements.forEach((element) => {
				const shouldDoMagicAdjustment =
					getShouldDoMagicalPositionAdjustment(
						element,
						whoSigns,
						isUnifiedEsign,
					);
				if (
					// Is a signature that was submitted by someone else (not the current recipient)
					getIsSubmittedSignature(element, currentRecipientEmail) &&
					element.recipient!.xfdfSignature
				) {
					submittedSignatures.push({
						x: shouldDoMagicAdjustment
							? calcMagicalXPositionAdjustment(element.left)
							: element.left,
						y: shouldDoMagicAdjustment
							? calcMagicalYPositionAdjustment(element.top)
							: element.top,
						width: isNum(element.imageWidth)
							? Number(element.imageWidth)
							: element.width,
						height: isNum(element.imageHeight)
							? Number(element.imageHeight)
							: element.height,
						pageNumber: currentPageNumber,
						xfdf: element.recipient!.xfdfSignature!,
					});
				} else if (
					// Is a signature that was submitted by someone else (not the current recipient)
					getIsSubmittedSignature(element, currentRecipientEmail) &&
					element.recipient!.image
				) {
					submittedSignatures.push({
						x: shouldDoMagicAdjustment
							? calcMagicalXPositionAdjustment(element.left)
							: element.left,
						y: shouldDoMagicAdjustment
							? calcMagicalYPositionAdjustment(element.top)
							: element.top,
						pageNumber: currentPageNumber,
						width: isNum(element.imageWidth)
							? Number(element.imageWidth)
							: element.width,
						height: isNum(element.imageHeight)
							? Number(element.imageHeight)
							: element.height,
						image: element.recipient!.image!,
					});
				}
			});
			currentPageNumber += 1;
		});
	});

	return submittedSignatures;
};

/**
 * Extracts dates that were submitted by "other" recipients (IE not the
 * current recipient) from the signature package
 *
 * This function returns page numbers for a combined document.
 */
export const extractDates = (
	signaturePackage: SignaturePackage,
	currentRecipientEmail: string,
): Date[] => {
	const { whoSigns, isUnifiedEsign, lawyawDocuments } = signaturePackage;
	const dates: Date[] = [];

	const dateIncluder = new DateElementIncluder(
		signaturePackage,
		currentRecipientEmail,
	);

	let currentPageNumber = 1;
	lawyawDocuments.forEach((document) => {
		document.pages.forEach((page) => {
			page.elements.forEach((element) => {
				const shouldDoMagicAdjustment =
					getShouldDoMagicalPositionAdjustment(
						element,
						whoSigns,
						isUnifiedEsign,
					);

				// If it's a legacy element, it's already embedded in the PDF so we can skip it
				if (dateIncluder.getIsLegacyElement(element)) return;

				if (dateIncluder.getIsSubmittedBySomeoneElse(element)) {
					dates.push({
						x: shouldDoMagicAdjustment
							? calcMagicalXPositionAdjustment(element.left)
							: element.left,
						y: shouldDoMagicAdjustment
							? calcMagicalYPositionAdjustment(element.top)
							: element.top,
						width: element.width,
						height: element.height,
						pageNumber: currentPageNumber,
						date: element.value,
					});
				} else if (dateIncluder.getIsMine(element)) {
					dates.push({
						x: shouldDoMagicAdjustment
							? calcMagicalXPositionAdjustment(element.left)
							: element.left,
						y: shouldDoMagicAdjustment
							? calcMagicalYPositionAdjustment(element.top)
							: element.top,
						width: element.width,
						height: element.height,
						pageNumber: currentPageNumber,
						date: moment().format('MM/DD/YYYY'),
					});
				}
			});
			currentPageNumber += 1;
		});
	});

	return dates;
};

/**
 * Extracts the locations where the current recipient's signature is needed
 * on the combined document.
 *
 * This function returns page numbers for a combined document.
 */
export const extractNeededSignatures = (
	signaturePackage: SignaturePackage,
	currentRecipientEmail: string,
) => {
	const { whoSigns, isUnifiedEsign, lawyawDocuments } = signaturePackage;
	const neededSignatures: NeededSignature[] = [];

	let currentPageNumber = 1;
	lawyawDocuments.forEach((document) => {
		document.pages.forEach((page) => {
			page.elements.forEach((element) => {
				const shouldDoMagicAdjustment =
					getShouldDoMagicalPositionAdjustment(
						element,
						whoSigns,
						isUnifiedEsign,
					);
				if (getIsRecipientSignature(element, currentRecipientEmail)) {
					neededSignatures.push({
						id: element.id,
						x: shouldDoMagicAdjustment
							? calcMagicalXPositionAdjustment(element.left)
							: element.left,
						y: shouldDoMagicAdjustment
							? calcMagicalYPositionAdjustment(element.top)
							: element.top,
						pageNumber: currentPageNumber,
						width: element.width,
						height: element.height,
					});
				}
			});
			currentPageNumber += 1;
		});
	});

	return neededSignatures;
};

/**
 * Makes a document, including signatures and dates, for Hancock's consumption.
 * No adjustment is made to page numbers in this function because we are
 * going to ask Hancock to take a number of documents and merge them together.
 */
const makeSingleDoc = (
	signaturePackage: SignaturePackage,
	document: Document,
	whoSigns: WhoSigns,
	isUnifiedEsign: boolean,
	currentRecipientEmail: string,
) => {
	const allElements = document.pages
		.map((page) =>
			page.elements.map((e) => ({ ...e, pageNumber: page.pageNumber })),
		)
		.flat();

	const neededSignatures: NeededSignature[] = allElements
		.filter((element) =>
			getIsRecipientSignature(element, currentRecipientEmail),
		)
		.map((element) => {
			const shouldDoMagicAdjustment =
				getShouldDoMagicalPositionAdjustment(
					element,
					whoSigns,
					isUnifiedEsign,
				);
			return {
				id: element.id,
				x: shouldDoMagicAdjustment
					? calcMagicalXPositionAdjustment(element.left)
					: element.left,
				y: shouldDoMagicAdjustment
					? calcMagicalYPositionAdjustment(element.top)
					: element.top,
				pageNumber: element.pageNumber,
				width: element.width,
				height: element.height,
			};
		});

	const signatures: Signature[] = allElements
		.filter(
			(element) =>
				getIsSubmittedSignature(element, currentRecipientEmail) &&
				!getIsLegacyProXLegacyAttorneyElement(
					element,
					whoSigns,
					isUnifiedEsign,
				),
		)
		.map((element): Signature => {
			const shouldDoMagicAdjustment =
				getShouldDoMagicalPositionAdjustment(
					element,
					whoSigns,
					isUnifiedEsign,
				);
			if (element.recipient?.xfdfSignature) {
				return {
					x: shouldDoMagicAdjustment
						? calcMagicalXPositionAdjustment(element.left)
						: element.left,
					y: shouldDoMagicAdjustment
						? calcMagicalYPositionAdjustment(element.top)
						: element.top,
					pageNumber: element.pageNumber,
					xfdf: element.recipient.xfdfSignature!,
					width: isNum(element.imageWidth)
						? Number(element.imageWidth)
						: element.width,
					height: isNum(element.imageHeight)
						? Number(element.imageHeight)
						: element.height,
				};
			}
			return {
				x: shouldDoMagicAdjustment
					? calcMagicalXPositionAdjustment(element.left)
					: element.left,
				y: shouldDoMagicAdjustment
					? calcMagicalYPositionAdjustment(element.top)
					: element.top,
				pageNumber: element.pageNumber,
				image: element.recipient!.image!,
				width: isNum(element.imageWidth)
					? Number(element.imageWidth)
					: element.width,
				height: isNum(element.imageHeight)
					? Number(element.imageHeight)
					: element.height,
			};
		});

	const dateIncluder = new DateElementIncluder(
		signaturePackage,
		currentRecipientEmail,
	);

	const dates: Date[] = allElements
		.filter((element) => {
			// Legacy element (IE already embedded in PDF)
			if (dateIncluder.getIsLegacyElement(element)) return false;

			if (dateIncluder.getIsSubmittedBySomeoneElse(element)) return true;

			return dateIncluder.getIsMine(element);
		})
		.map((element) => {
			const shouldDoMagicAdjustment =
				getShouldDoMagicalPositionAdjustment(
					element,
					whoSigns,
					isUnifiedEsign,
				);
			return {
				x: shouldDoMagicAdjustment
					? calcMagicalXPositionAdjustment(element.left)
					: element.left,
				y: shouldDoMagicAdjustment
					? calcMagicalYPositionAdjustment(element.top)
					: element.top,
				width: element.width,
				height: element.height,
				pageNumber: element.pageNumber,
				date: element.value || moment().format('MM/DD/YYYY'),
			};
		});
	return {
		documentPath: document.originalFileUrl!,
		neededSignatures,
		signatures,
		dates,
	};
};

/**
 * Makes the document set that hancock needs in order to render
 * the signer's view.
 */
export const makeDocumentsForHancock = (
	signaturePackage: SignaturePackage,
	currentRecipientEmail: string,
): SignDocuments => {
	const { generatedCombinedPdf, whoSigns, isUnifiedEsign, lawyawDocuments } =
		signaturePackage;
	if (!generatedCombinedPdf) {
		return lawyawDocuments.map((e) =>
			makeSingleDoc(
				signaturePackage,
				e,
				whoSigns,
				isUnifiedEsign,
				currentRecipientEmail,
			),
		);
	}
	/**
	 * Be aware that signatures, dates, and needed signatures all have page
	 * numbers that reflect the page numbers of the final merged document.
	 */
	const signatures = extractSubmittedSignatures(
		signaturePackage,
		currentRecipientEmail,
	);
	const dates = extractDates(signaturePackage, currentRecipientEmail);
	const neededSignatures = extractNeededSignatures(
		signaturePackage,
		currentRecipientEmail,
	);
	return {
		documentPath: generatedCombinedPdf,
		signatures,
		dates,
		neededSignatures,
	};
};

/**
 * Makes the document package that Hancock can render in a
 * read-only way for the details view.
 */
export const makeReadOnlyDocumentsForHancock = (
	signaturePackage: SignaturePackage,
	currentRecipientEmail: string,
): ReadDocuments => {
	const { generatedCombinedPdf, whoSigns, isUnifiedEsign, lawyawDocuments } =
		signaturePackage;
	if (!generatedCombinedPdf) {
		return lawyawDocuments.map((e) =>
			makeSingleDoc(
				signaturePackage,
				e,
				whoSigns,
				isUnifiedEsign,
				currentRecipientEmail,
			),
		);
	}
	/**
	 * Be aware that signatures, dates, and needed signatures all have page
	 * numbers that reflect the page numbers of the final merged document.
	 */
	const signatures = extractSubmittedSignatures(
		signaturePackage,
		currentRecipientEmail,
	);
	const dates = extractDates(signaturePackage, currentRecipientEmail);
	return { documentPath: generatedCombinedPdf, signatures, dates };
};

/**
 * Gets the package ID that we want Hancock to render
 * and the position on every page to render it at
 */
export const getPackageIdAndPosition = (signaturePackage: SignaturePackage) => {
	const packageId =
		signaturePackage.lawyawDocuments[0].pages[0].elements.find(
			(e) => e.value && e.value.includes('Package ID'),
		)!;
	return {
		position: {
			x: packageId.left,
			y: packageId.top,
		},
		id: packageId.value,
	};
};
