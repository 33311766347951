import Cookies from 'js-cookie';

const CSRF_KEY = 'csrftoken';

const getHeaderTokens = () => {
	return {
		'X-CSRFToken': Cookies.get(CSRF_KEY),
	};
};

export default {
	getHeaderTokens,
};
