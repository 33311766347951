import { Element } from '~/src/types';
import { WHO_SIGNS_TYPES } from '../constants';

export const getIsSubmittedSignature = (
	element: Element,
	currentRecipientEmail: string,
) =>
	element.recipient &&
	element.recipient.email !== currentRecipientEmail &&
	element.recipient.submitted === true &&
	element.inputType === 5 &&
	// (prox_esign refers to signed via platform not standalone so its skipped since that renders within pdf)
	element.recipient.font !== 'prox_esign';

export const getIsRecipientSignature = (
	element: Element,
	currentRecipientEmail: string,
) =>
	element.inputType === 5 &&
	(!element.recipient || !element.recipient.submitted) &&
	element.recipient &&
	element.recipient.email === currentRecipientEmail;

/**
 * If this signature package was created before the 2022 release of e-sign,
 * any dates and signatures added by the attorney during the prepare step are
 * already embedded in the PDF. If this is the case, we can skip rendering
 * those elements since they're in the PDF already.
 *
 * This ONLY applies to elements added by the attorney while preparing the
 * document. It does NOT apply to signatures added by any recipients.
 */
export const getIsLegacyProXLegacyAttorneyElement = (
	element: Element,
	whoSigns: 1 | 2 | 3,
	isUnifiedEsign: boolean,
) =>
	// We should skip if we are rendering a legacy package (created in Pro X before the new e-sign release)...
	!isUnifiedEsign &&
	// ...Where the attorney is a signer...
	(whoSigns === WHO_SIGNS_TYPES.JUST_ME ||
		whoSigns === WHO_SIGNS_TYPES.ME_AND_OTHERS) &&
	// ...And this element is an attorney's element
	element.recipient &&
	element.recipient.signOrder === 0;

export const getShouldDoMagicalPositionAdjustment = (
	element: Element,
	whoSigns: 1 | 2 | 3,
	isUnifiedEsign: boolean,
) => {
	if (getIsLegacyProXLegacyAttorneyElement(element, whoSigns, isUnifiedEsign))
		return false;
	return !isUnifiedEsign;
};

/**
 * There's no special logic to these adjustments. They're just mirroring some logic in the
 * legacy version of Pro X that makes no sense but is unavoidable.
 *
 * For more context, search for these numbers here: https://github.com/mystacksco/lawyaw-pro/blob/ee75f890a70dccb6a6455ad9555b76f05a772438/src/hooks/useBackend.js
 */
const SCALE_FACTOR = 1.34;
export const calcMagicalXPositionAdjustment = (originalCoordinate: number) => {
	return originalCoordinate >= 0
		? (originalCoordinate / SCALE_FACTOR) * 1.015
		: 150;
};

export const calcMagicalYPositionAdjustment = (originalCoordinate: number) => {
	return originalCoordinate >= 0
		? (originalCoordinate / SCALE_FACTOR) * 1.015
		: 200;
};
