import moment from 'moment-timezone';
import { http } from './requests';
import { Document, Element, Page, Recipient, SignaturePackage } from '../types';

function apiService() {
	return {
		async getSignaturePackageDetails() {
			const apiPathMatches =
				window.location.pathname.match(/\e\/[0-9a-zA-Z-]+/) || [];
			let apiSuffix = '/details/';

			if (window.location.pathname.includes('packageDetailsView')) {
				apiSuffix = '/package-details/';
			}
			const apiPath = apiPathMatches[0];
			return await http.innerSendAndParse({
				relativePath: apiPath + apiSuffix,
				method: 'GET',
			});
		},
		async uploadUserSignatureImage({
			signatureImageBlob,
			recipientFprint,
			signatureImageType,
		}: {
			signatureImageBlob: any;
			recipientFprint: string;
			signatureImageType: string;
		}) {
			const signatureImageData = new FormData();
			if (signatureImageType === 'png') {
				signatureImageData.append('photofile', signatureImageBlob);
			} else if (signatureImageType === 'xfdf') {
				signatureImageData.append('xfdf_signature', signatureImageBlob);
			} else {
				throw new Error('should be unreachable!');
			}
			signatureImageData.append('recipient_fprint', recipientFprint);
			return await http.formUpload({
				parse: true,
				relativePath: 'api/v1/image/',
				data: signatureImageData,
			});
		},
		async declineSignaturePackage({
			recipientFprint,
			declineReason,
		}: {
			recipientFprint: string;
			declineReason: string;
		}) {
			const parse = true;

			return await http.innerSendAndParse(
				{
					relativePath: 'api/v1/action/',
					body: {
						recipient_fprint: recipientFprint,
						action: 'decline',
						reason: declineReason,
					},
					method: 'POST',
				},
				// @ts-expect-error
				parse,
			);
		},
		async submitSignaturePackage(data: any) {
			const parse = true;
			return await http.innerSendAndParse(
				{
					relativePath: 'api/v1/action/',
					body: data,
					method: 'POST',
				},
				// @ts-expect-error
				parse,
			);
		},
	};
}

const api = apiService();

export async function getSignaturePackageDetails() {
	try {
		const data = await api.getSignaturePackageDetails();
		return {
			signaturePackage: data.signature_package
				? mapSignaturePackage(data.signature_package)
				: null,
			currentRecipientEmail: data.current_recipient_email,
			isPackageValid: data.is_package_valid,
			isDetailsView: data.is_details_view,
			/**
			 * Certain organizations on the "basic" plan (only one or two orgs as of July
			 * 2022) use esign-public for their recipients of packages, because esign-public
			 * is mobile friendly. In these instances, we must save the recipient signatures
			 * as PNG so that they can still be viewed on the basic platform.
			 */
			shouldSaveSignatureAsPng: data.should_save_signature_as_png,
		};
	} catch (error) {
		throw new Error('could not etch signature package details');
	}
}

export async function uploadUserSignatureImage({
	signatureImageBlob,
	recipientFprint,
	signatureImageType,
}: {
	signatureImageBlob: any;
	recipientFprint: string;
	signatureImageType: string;
}) {
	try {
		return await api.uploadUserSignatureImage({
			signatureImageBlob,
			recipientFprint,
			signatureImageType,
		});
	} catch (error) {
		return false;
	}
}

export async function declineSignaturePackage({
	recipientFprint,
	declineReason,
}: {
	recipientFprint: string;
	declineReason: string;
}) {
	try {
		const resp = await api.declineSignaturePackage({
			recipientFprint,
			declineReason,
		});
		return true;
	} catch (error) {
		throw new Error('could not decline');
	}
}

export async function submitSignaturePackage({
	recipientFprint,
	signatureElements,
}: {
	recipientFprint: string;
	signatureElements: any[];
}) {
	try {
		const signingDate = moment().tz(moment.tz.guess()).format();
		const submissionData = {
			recipient_fprint: recipientFprint,
			action: 'submit',
			elements: signatureElements,
			signing_date: signingDate,
		};
		const resp = await api.submitSignaturePackage(submissionData);
		return true;
	} catch (error) {
		return false;
	}
}

const mapSignaturePackage = (signaturePackage: any): SignaturePackage => {
	const signaturePackageData: SignaturePackage = {
		id: signaturePackage.id,
		lawyawProjectId: signaturePackage.lawyaw_project_id,
		lawyawClientId: signaturePackage.lawyaw_client_id,
		isActive: signaturePackage.is_active,
		isUnifiedEsign: signaturePackage.is_unified_esign,
		fprint: signaturePackage.fprint,
		createdAt: signaturePackage.created_at,
		packageStatus: signaturePackage.package_status,
		lawyawDocumentIds: signaturePackage.lawyaw_document_ids,
		whoSigns: signaturePackage.who_signs,
		freeze: signaturePackage.freeze,
		title: signaturePackage.title,
		emailSubject: signaturePackage.email_subject,
		emailBody: signaturePackage.email_body,
		emailCc: signaturePackage.email_cc,
		generatedCombinedPdf: signaturePackage.generated_combined_pdf,
		lawyawDocuments: mapSignaturePackageDocuments(
			signaturePackage.lawyaw_documents,
		),
		recipients: signaturePackage.recipients.map((recipient: any) =>
			mapRecipient(recipient),
		),
	};
	if (signaturePackage.audit_trail) {
		signaturePackageData.auditTrail = signaturePackage.audit_trail.map(
			(auditTrail: any) => mapAuditTrail(auditTrail),
		);
	}
	return signaturePackageData;
};

const mapRecipient = (recipient: any): Recipient => ({
	id: recipient.id,
	name: recipient.name,
	email: recipient.email,
	font: recipient.font,
	submitted: recipient.submitted,
	signOrder: recipient.sign_order,
	text: recipient.text,
	status: recipient.status,
	image: recipient.image,
	xfdfSignature: recipient.xfdf_signature,
});

const mapSignaturePackageDocumentPages = (documentPages: any): Page[] => {
	return documentPages.map((documentPage: any) => {
		return {
			id: documentPage.id,
			pageNumber: documentPage.page_number,
			backgroundUrl: documentPage.background_url,
			elements: documentPage.elements.map((element: any) =>
				mapSignaturePackageElement(element),
			),
		};
	});
};
const mapSignaturePackageElement = (element: any): Element => {
	const elementData: Element = {
		id: element.id,
		signaturePageId: element.signature_page_id || element.pageid,
		top: element.top,
		left: element.left,
		width: element.width,
		imageWidth: element.image_width,
		imageHeight: element.image_height,
		lineHeight: element.line_height,
		value: element.value,
		height: element.height,
		inputType: element.input_type,
	};
	if (element.recipient) {
		elementData.recipient = mapRecipient(element.recipient);
	}
	return elementData;
};
const mapSignaturePackageDocuments = (
	signaturePackageDocuments: any,
): Document[] => {
	return signaturePackageDocuments.map((signaturePackageDocument: any) => {
		return {
			id: signaturePackageDocument.id,
			title: signaturePackageDocument.title,
			subtitle: signaturePackageDocument.subtitle,
			sortOrder: signaturePackageDocument.sort_order,
			originalFileUrl: signaturePackageDocument.original_file_url,
			pages: mapSignaturePackageDocumentPages(
				signaturePackageDocument.pages,
			),
		};
	});
};

const mapAuditTrail = (auditTrail: any) => ({
	actionText: auditTrail.action_text,
	ipAddress: auditTrail.ip_address,
	recipient: mapRecipient(auditTrail.recipient),
	timestamp: auditTrail.timestamp,
	timezoneOffset: auditTrail.timezone_offset,
});
